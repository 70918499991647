import { useContext } from '@nuxtjs/composition-api'
import { detectServerBaseUrl, findTLD } from '@cstweb/common'
import { TLD } from '~/common/constants/utils'

export function useBaseUrl() {
  const { req } = useContext()
  let host = ''

  if (process.server) {
    host = process.static ? '' : detectServerBaseUrl(req)!
  }
  if (process.client) {
    host = location.origin
  }

  return host.endsWith('/') ? host.slice(0, -1) : host // remove trailing slash
}

export function useCanonicalUrl(url?: string) {
  const ctx = useContext()
  let host

  if (process.server) {
    host = process.static ? '' : detectServerBaseUrl(ctx.req)
  }
  if (process.client) {
    host = location.origin
  }

  const hostExtension = findTLD(host)?.toLowerCase()
  let canonicalUrl = ctx.$config.cst.domainDefault

  if (hostExtension === TLD.CN.toLowerCase()) {
    canonicalUrl = ctx.$config.cst.domainChina
    //
  } else if (hostExtension === TLD.JP.toLowerCase()) {
    canonicalUrl = ctx.$config.cst.domainJapan
  }

  if (!url) {
    canonicalUrl += ctx.route.value.fullPath
  } else {
    canonicalUrl += url
  }

  return canonicalUrl
}
