import { useContext, useMeta } from '@nuxtjs/composition-api'
import { META_TAGS } from '~/common/utils/product/constants'
import { Metadata } from '~/types/cms/metadata'

interface SEOMetaDefaultValues {
  title?: string
  meta?: any[]
  link?: any[]
}

interface SEOType {
  type: string
  category: 'meta' | 'link'
}

export function useCMSMeta(
  cmsMetadata?: Metadata[],
  defaultValues?: SEOMetaDefaultValues | (() => SEOMetaDefaultValues)
) {
  const cmsSEOTypes: SEOType[] = [
    { type: META_TAGS.TITLE, category: 'meta' },
    { type: META_TAGS.OG_TITLE, category: 'meta' },
    { type: META_TAGS.TWITTER_TITTLE, category: 'meta' },
    { type: META_TAGS.DESCRIPTION, category: 'meta' },
    { type: META_TAGS.OG_DESCRIPTION, category: 'meta' },
    { type: META_TAGS.TWITTER_DESCRIPTION, category: 'meta' },
    { type: META_TAGS.OG_IMAGE, category: 'meta' },
    { type: META_TAGS.OG_IMAGE_WIDTH, category: 'meta' },
    { type: META_TAGS.OG_IMAGE_HEIGHT, category: 'meta' },
    { type: META_TAGS.TWITTER_IMAGE, category: 'meta' },
    { type: 'image_src', category: 'link' },
  ]
  const ctx = useContext()

  const getMetaObject = (type: string, value: string, entity: string) => {
    const typeKey = type.includes('og:') ? 'property' : 'name'
    return {
      hid: type,
      [typeKey]: type,
      content: entity || value,
    }
  }

  useMeta(() => {
    const meta = cmsSEOTypes
      .sort((a, b) => {
        // Sort types so more specific types are at the end and override inherited values
        const prefixes = ['og:', 'twitter:']

        const hasPrefix = (str: string, prefix: string) => str.startsWith(prefix)

        const aHasPrefix = prefixes.some((prefix) => hasPrefix(a.type, prefix))
        const bHasPrefix = prefixes.some((prefix) => hasPrefix(b.type, prefix))

        if (aHasPrefix && !bHasPrefix) {
          return 1
        } else if (!aHasPrefix && bHasPrefix) {
          return -1
        } else {
          return a.type.localeCompare(b.type)
        }
      })
      .reduce((acc, { type, category }) => {
        const cmsMetaItem = cmsMetadata?.find((item) => item.type === type)
        const metaItemValue = cmsMetaItem?.value
        const metaItemEntity = cmsMetaItem?.entity
          ? ctx.$img(cmsMetaItem.entity, null, { provider: 'mc' }) + '/1X'
          : null

        if (!metaItemValue && !metaItemEntity) {
          return { ...acc }
        }

        if (type === META_TAGS.TITLE) {
          return {
            ...acc,
            title: metaItemValue,
            meta: [
              getMetaObject('og:' + type, metaItemValue as string, metaItemEntity as string),
              getMetaObject('twitter:' + type, metaItemValue as string, metaItemEntity as string),
              ...(acc.meta || []),
            ],
          }
        }

        if (category === 'link') {
          const alreadyDefinedLinkIndex = acc.link?.findIndex((link: any) => link.rel === type)
          const inheritedMeta =
            type === 'image_src'
              ? [
                  getMetaObject('og:image', metaItemValue as string, metaItemEntity as string),
                  getMetaObject('twitter:image', metaItemValue as string, metaItemEntity as string),
                ]
              : []

          if (alreadyDefinedLinkIndex >= 0) {
            return {
              ...acc,
              link: acc.link.map((link: any, index: number) =>
                index === alreadyDefinedLinkIndex ? { rel: type, href: metaItemEntity } : link
              ),
              meta: [...inheritedMeta, ...(acc.meta || [])],
            }
          }
          return {
            ...acc,
            link: [...(acc.link || []), { rel: type, href: metaItemEntity }],
            meta: [...inheritedMeta, ...(acc.meta || [])],
          }
        }

        if (!type.includes('og:') && !type.includes('twitter:')) {
          return {
            ...acc,
            meta: [
              getMetaObject(type, metaItemValue as string, metaItemEntity as string),
              getMetaObject('og:' + type, metaItemValue as string, metaItemEntity as string),
              getMetaObject('twitter:' + type, metaItemValue as string, metaItemEntity as string),
              ...(acc.meta || []),
            ],
          }
        }

        return {
          ...acc,
          meta: [getMetaObject(type, metaItemValue as string, metaItemEntity as string), ...(acc.meta || [])],
        }
      }, (typeof defaultValues === 'function' ? defaultValues() : defaultValues || {}) as any)

    return meta
  })
}
