




import { storeToRefs } from 'pinia'
import { detectServerBaseUrl, findTLD } from '@cstweb/common'
import { defineComponent, useContext, useMeta } from '@nuxtjs/composition-api'

import { DEVKEY } from '../common/constants/cms'

// Stores
import { useCMSGlobalSettingsStore, usePageStore } from '~/store'
import { META_TAGS } from '~/common/utils/product/constants'
import { TLD } from '~/common/constants/utils'
import { useCMSMeta } from '~/composables/cms/useCMSMeta'
import { useCanonicalUrl } from '@/composables/useBaseUrl'

// Components
import Homepage from '@/components/feature/content/homepage/variations/b/index.vue'

export default defineComponent({
  // Load components asynchronously to make sure they are only loaded when needed based on the feature flag
  components: {
    Homepage,
  },
  layout: 'homepage',
  setup() {
    const { getAttributeByCountry } = useCMSGlobalSettingsStore()
    const ctx = useContext()
    const { data, isLoading } = storeToRefs(usePageStore())
    const canonicalUrl = useCanonicalUrl()

    // Set the meta tags for title and description and ld+json scripts
    useCMSMeta(data.value?.seoMetadata, () => {
      let host

      if (process.server) {
        host = process.static ? '' : detectServerBaseUrl(ctx.req)
      }
      if (process.client) {
        host = location.origin
      }

      const hostExtension = findTLD(host)?.toLowerCase()
      let technicalSupportPhone = '+1-978-867-2388'
      let mediaUrl = ctx.$config.cst.mediaUrl

      if (hostExtension === TLD.CN.toLowerCase()) {
        technicalSupportPhone = '4006-473287'
        mediaUrl = ctx.$config.cst.cn.mediaUrl
        //
      } else if (hostExtension === TLD.JP.toLowerCase()) {
        technicalSupportPhone = '03 (3295) 1632'
      }

      return {
        title: 'Antibodies, Reagents, Proteomics, Kits and Consumables',
        link: [
          {
            rel: 'image_src',
            type: 'image/png',
            href: mediaUrl + '/www/images/global/cst-social-default-global.png',
          },
          {
            media: 'all',
            rel: 'canonical',
            href: canonicalUrl,
          },
          {
            rel: 'alternate',
            hreflang: 'en-US',
            href: ctx.$config.cst.domainDefault,
          },
          {
            rel: 'alternate',
            hreflang: 'zh-CN',
            href: ctx.$config.cst.domainChina,
          },
          { rel: 'alternate', hreflang: 'ja-JP', href: ctx.$config.cst.domainJapan },
          { rel: 'alternate', hreflang: 'en-JP', href: ctx.$config.cst.domainDefault },
          { rel: 'alternate', hreflang: 'x-default', href: ctx.$config.cst.domainDefault },
        ],
        meta: [
          {
            hid: META_TAGS.DESCRIPTION,
            name: META_TAGS.DESCRIPTION,
            content:
              'An antibody shouldn’t be one of the variables in your experiment. Find out why customers rank CST highest for antibody specificity and sensitivity.',
          },
          {
            httpEquiv: 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            hid: META_TAGS.OG_IMAGE,
            property: META_TAGS.OG_IMAGE,
            content: mediaUrl + '/www/images/global/cst-social-default-global.png',
          },
          {
            property: 'cst:page-title-analytics',
            content: 'Cell Signaling Technology (CST): Antibodies, Reagents, Proteomics, Kits and Consumables',
            class: 'OneLinkNoTx',
          },
          {
            name: 'msvalidate.01',
            content: '6E6B75594B9368D45BABCB439321C9B2',
          },
          { name: 'google-site-verification', content: 'qHBWEqzEmQR6wpQneO7wQF02vQzoeScap3ieBs6Nos0' },
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              '@context': 'http://schema.org',
              '@type': 'Corporation',
              name: 'Cell Signaling Technology',
              alternateName: ['CST', 'Cell Signaling'],
              description:
                "Cell Signaling Technology (CST) is a private, family-owned company, founded by scientists and dedicated to providing the world's highest quality, innovative research and diagnostic products to accelerate biological understanding and enable personalized medicine.",
              founder: {
                '@type': 'Person',
                name: 'Michael J. Comb',
                honorificPrefix: 'Dr',
                image: `${mediaUrl}/www/images/about/leadership/michael-comb.png`,
                url: `${host}/contents/about-us/our-company/leadership-team`,
                sameAs: 'https://www.linkedin.com/in/michael-comb-86928010',
              },
              url: host,
              logo:
                ctx.$config.cst.cloudflare.images.deliveryUrl +
                '/' +
                getAttributeByCountry('headerBrandLogo').image.cloudflareId +
                '/1X',
              contactPoint: {
                '@type': 'ContactPoint',
                telephone: technicalSupportPhone,
                contactType: 'technical support',
              },
              sameAs: [
                'https://www.facebook.com/cellsignal',
                'https://twitter.com/cellsignal',
                'https://www.instagram.com/cell_signaling_technology/',
                'https://www.linkedin.com/company/cell-signaling-technology/',
                'https://www.youtube.com/user/cellsignaldotcom',
                'https://en.wikipedia.org/wiki/Cell_Signaling_Technology',
                'https://www.wikidata.org/wiki/Q3392342',
                'https://www.bloomberg.com/profile/company/3340494Z:US',
                'https://www.crunchbase.com/organization/cell-signaling-technology',
              ],
            },
          },
          {
            type: 'application/ld+json',
            json: {
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              url: host,
              potentialAction: {
                '@type': 'SearchAction',
                target: {
                  '@type': 'EntryPoint',
                  urlTemplate: `https://www.cellsignal.com/browse?itemtype=product&status=Released&search={search_term_string}`,
                },
                'query-input': 'required name=search_term_string',
              },
            },
          } as any,
        ],
      }
    })

    return {
      storeToRefs,
      useMeta,
      usePageStore,
      data,
      isLoading,
    }
  },

  // Fetch homepage content from Contentstack
  async asyncData(ctx) {
    // Fetch this feature flag server side to get the right homepage variant
    try {
      await usePageStore(ctx.$pinia).fetchPageByDevKey(DEVKEY.HOMEPAGE, ctx)
    } catch (error: any) {
      const logName = { type: 'ContentStackAxiosError' }
      const newError = { ...error, ...logName }
      ctx.$logService.sendError(newError)

      ctx.error({ statusCode: error.response?.status, message: error.message })
    }
  },

  head: {},
})
